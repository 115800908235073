import merge from "deepmerge";
import {green, grey} from "@mui/material/colors";
import {THEMES} from "../redux/constants";

const customBlue = {
    50: "#e9f0fb",
    100: "#c8daf4",
    200: "#a3c1ed",
    300: "#7ea8e5",
    400: "#6395e0",
    500: "#4782da",
    600: "#407ad6",
    700: "#376fd0",
    800: "#2f65cb",
    900: "#2052c2",
};

const defaultVariant = {
    name: THEMES.DEFAULT,
    palette: {
        mode: "light",
        primary: {
            main: customBlue[700],
            contrastText: "#FFF",
        },
        secondary: {
            //main: customBlue[500],
            main: '#9e2a21',
            contrastText: "#FFF",
        },
        background: {
            /*default: "#f0f0f0",*/
            default: "#F7F9FC",
            paper: "#FFF",
        }
    },
    header: {
        color: grey[500],
        colorDark: grey[900],
        background: "#FFF",
        search: {
            color: grey[800],
        },
        indicator: {
            background: customBlue[600],
        },
        breadcrumbs: {
            border:'#eee'
        }
    },
    footer: {
        color: grey[500],
        background: "#FFF",
    },
    sidebar: {
        color: grey[200],
        background: "#233044",
        header: {
            color: grey[200],
            background: "#1E2A38",
            brand: {
                color: customBlue[500],
            },
        },
        footer: {
            color: grey[200],
            background: "#1E2A38",
            online: {
                background: green[500],
            },
        },
        badge: {
            color: "#FFF",
            background: customBlue[500],
        },
    },
    custom: {
        tracking: {
            label: {
                borderRight: "1px solid #dfe6ea"
            }
        }
    }
};

const darkVariant = merge(defaultVariant, {
    name: THEMES.DARK,
    palette: {
        mode: "dark",
        primary: {
            main: customBlue[600],
            contrastText: "#FFF",
        },
        background: {
            default: "#1B2635",
            paper: "#233044",
        },
        text: {
            primary: "rgba(255, 255, 255, 0.95)",
            secondary: "rgba(255, 255, 255, 0.5)",
        }
    },
    header: {
        color: grey[300],
        colorDark: grey[300],
        background: "#1B2635",
        search: {
            color: grey[200],
        },
        breadcrumbs: {
            border: 'transparent',
            background: 'rgba(0, 0, 0, 0.2)'
        },
    },
    footer: {
        color: grey[300],
        background: "#233044",
    },
    custom: {
        tracking: {
            label: {
                borderRight: "1px solid rgba(255,255,255,0.12)"
            }
        }
    }
});

const variants = [
    defaultVariant,
    darkVariant
];

export default variants;
