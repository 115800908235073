import * as types from "../constants";
import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from "../constants";
import UserService from "../../services/UserService";
import NotificationService from "../../services/NotificationService";

export const setTheme = (value) => {
    return async (dispatch, getState) => {

        const snack = (...args) => dispatch(enqueueSnackbar(...args));
        const { auth: { user } } = getState();
        const values = {
            preferences: {
                theme: value
            }
        };

        const data = await UserService.update(user.id, values);
        if (data.data) {
            dispatch({ type: types.THEME_SET, payload: value });
        } else {
            snack({ message: "Couldn't update theme, please try again.", options: { variant: "error" }});
        }
    };
};

export const setNotifications = () => {
    return async (dispatch, getState) => {
        dispatch({ type: types.SET_NOTIFICATIONS, payload: [] });

        const { auth: { user } } = getState();
        if (user) {
            const notifications = await NotificationService.find({ 'user.id': user.id });
            if (notifications?.data) {
                dispatch({ type: types.SET_NOTIFICATIONS, payload: notifications.data });
            }
        }
    };
};

export const enqueueSnackbar = (snack) => {
    const key = snack.options && snack.options.key;

    return {
        type: ENQUEUE_SNACKBAR,
        snack: {
            ...snack,
            key: key || new Date().getTime() + Math.random()
        }
    };
};

export const closeSnackbar = key => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key
});

export const removeSnackbar = key => ({
    type: REMOVE_SNACKBAR,
    key
});