import axios from "axios";

const find = async (find = null, select = null) => {
    return await axios.get('/notifications', { params: { find: { ...find }, select: select } } );
};

const findById = async (id) => {
    return await axios.get('/notifications/' + id);
};

const create = async (data) => {
    return await axios.post('/notifications', data);
};

const update = async (id, data) => {
    return await axios.put('/notifications/' + id, data);
};

const remove = async (id) => {
    return await axios.delete('/notifications/' + id);
};

const removeAll = async (data) => {
    return await axios.delete('/notifications', { data: data });
};

const NotificationService = {
    find,
    findById,
    create,
    update,
    remove,
    removeAll
}

export default NotificationService;