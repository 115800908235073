import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('882ae42335f655d22e3e5b8b0222ee0bTz00Njk5OSxFPTE2ODg4NDk4OTg5NTYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

Sentry.init({
    dsn: "https://0d73eaede051441b8a7b0d2aba1507c3@o1049068.ingest.sentry.io/6724208",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENV === 'dev' ? 1.0 : 0.25,
    enabled: process.env.REACT_APP_ENV !== 'dev'
});




ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
