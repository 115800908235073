import React, {useState} from "react";
import styled from "styled-components/macro";
import {rgba} from "polished";
import {NavLink, withRouter} from "react-router-dom";
import {darken} from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";
import {spacing} from "@mui/system";

import {
    Box as MuiBox,
    Chip,
    Collapse,
    Drawer as MuiDrawer,
    List as MuiList,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import {ExpandLess, ExpandMore, Language} from "@mui/icons-material";
import routes from "../../routes";
import {hasRole} from "../auth/PrivateRoute";
import {useSelector} from "react-redux";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  //padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.logo.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(8)};
  padding-right: ${(props) => props.theme.spacing(7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
            darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;

  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.2)};
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
            darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
            darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};

  span {
    font-size: ${(props) => props.theme.typography.body3.fontSize}px;
  }

  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.9;
  display: block;
`;

const SidebarCategory = ({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) => {
    return (
        <Category {...rest}>
            {icon}
            <CategoryText>{name}</CategoryText>
            {isCollapsable ? (
                isOpen ? (
                    <CategoryIconMore/>
                ) : (
                    <CategoryIconLess/>
                )
            ) : null}
            {badge ? <CategoryBadge label={badge}/> : ""}
        </Category>
    );
};

const SidebarLink = ({name, to, badge, icon, sep}) => {
    return (
        <React.Fragment>
        <Link
            button
            dense
            component={NavLink}
            exact
            to={to}
            activeClassName="active"
        >
            {icon}
            <LinkText>{name}</LinkText>
            {badge ? <LinkBadge label={badge}/> : ""}
        </Link>
        {sep && <span className="nav-section-sep">&nbsp;</span> }
        </React.Fragment>
    );
};

const Sidebar = ({classes, staticContext, location, ...rest}) => {

    const user = useSelector((state) => state.auth.user);

    const initOpenRoutes = () => {
        /* Open collapse element that matches current url */
        const pathName = location.pathname;

        let _routes = {};

        routes.adminRoutes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/";

            _routes = Object.assign({}, _routes, {
                [index]: isActive || isOpen || isHome,
            });
        });

        return _routes;
    };

    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

    const toggle = (index) => {
        // Collapse all elements
        Object.keys(openRoutes).forEach(
            (item) =>
                openRoutes[index] ||
                setOpenRoutes((openRoutes) =>
                    Object.assign({}, openRoutes, {[item]: false})
                )
        );

        // Toggle selected element
        setOpenRoutes((openRoutes) =>
            Object.assign({}, openRoutes, {[index]: !openRoutes[index]})
        );
    };

    return (
        <Drawer variant="permanent" {...rest}>
            <Brand component={NavLink} to="/dashboard" button>
                <Language/>
                <Box ml={1} style={{ marginLeft: '5px'}}>
                    1 AA XPRESS
                </Box>
            </Brand>
            <Scrollbar>
                <List disablePadding>
                    <Items>
                        {routes.adminRoutes.map((category, index) => (
                            <React.Fragment key={index}>
                                {category.roles && hasRole(user, category.roles) ? (
                                    <React.Fragment key={index}>
                                        {category.header ? (
                                            <SidebarSection>{category.header}</SidebarSection>
                                        ) : null}

                                        {category.children && category.icon ? (
                                            <React.Fragment key={index}>
                                                <SidebarCategory
                                                    isOpen={!openRoutes[index]}
                                                    isCollapsable={true}
                                                    name={category.id}
                                                    icon={category.icon}
                                                    button={true}
                                                    onClick={() => toggle(index)}
                                                />

                                                <Collapse
                                                    in={openRoutes[index]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    {category.children.map((route, index) => {
                                                        if (route.roles && hasRole(user, route.roles)) {
                                                            return (
                                                            <React.Fragment key={index}>
                                                                {!route.excludeFromSidebar ? (
                                                                    <SidebarLink
                                                                        key={index}
                                                                        name={route.name}
                                                                        to={route.path}
                                                                        icon={route.icon}
                                                                        badge={route.badge}
                                                                        sep={route.showSep}
                                                                    />
                                                                ) : null}
                                                            </React.Fragment>
                                                            )
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </Collapse>
                                            </React.Fragment>
                                        ) : category.icon ? (
                                            <React.Fragment>
                                                {!category.excludeFromSidebar ? (
                                                    <SidebarCategory
                                                        isCollapsable={false}
                                                        name={category.id}
                                                        to={category.path}
                                                        activeClassName="active"
                                                        component={NavLink}
                                                        icon={category.icon}
                                                        exact
                                                        button
                                                        badge={category.badge}
                                                    />
                                                ) : null}
                                            </React.Fragment>
                                        ) : null}
                                    </React.Fragment>
                                    ) : null}
                            </React.Fragment>
                            ))}
                    </Items>
                </List>
            </Scrollbar>
        </Drawer>
    );
};

export default withRouter(Sidebar);
