import React from "react";
import {
    BiDirections, BiPackage,
    FiUsers,
    RiContactsBook2Line,
    RiDashboard3Line,
    RiTruckLine,
    RiMoneyDollarBoxLine,
    IoDocumentTextOutline
} from "react-icons/all";

// Public Routes
const TrackShipment = React.lazy(() => import("../views/public/TrackShipment").then());
const DriverApplication = React.lazy(() => import("../views/public/drivers/application/Main").then());

// Auth Routes
const SignIn = React.lazy(() => import("../views/auth/SignIn").then());


/**
 * Admin Routes
 * Admin prefix to admin routes
 */
export const adminPrefix = '/admin';

const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard").then());

const Shipments = React.lazy(() => import("../views/shipments/Shipments").then());
const ShipmentCreateUpdate = React.lazy(() => import("../views/shipments/ShipmentCreateUpdate").then());
const ShipmentView = React.lazy(() => import("../views/shipments/ShipmentView").then());

const Drivers = React.lazy(() => import("../views/drivers/Drivers").then());
const DriverCreateUpdate = React.lazy(() => import("../views/drivers/DriverCreateUpdate").then());

const Loads = React.lazy(() => import("../views/loads/Loads").then());
const LoadCreateUpdate = React.lazy(() => import("../views/loads/LoadCreateUpdate").then());

const Carriers = React.lazy(() => import("../views/carriers/Carriers").then());
const CarrierCreateUpdate = React.lazy(() => import("../views/carriers/CarrierCreateUpdate").then());

const Contracts = React.lazy(() => import("../views/carriers/Contracts").then());
const ContractCreateUpdate = React.lazy(() => import("../views/carriers/ContractCreateUpdate").then());

const Bols = React.lazy(() => import("../views/carriers/Bols").then());
const BolCreateUpdate = React.lazy(() => import("../views/carriers/BolCreateUpdate").then());

const Units = React.lazy(() => import("../views/units/Units").then());
const UnitCreateUpdate = React.lazy(() => import("../views/units/UnitCreateUpdate").then());

const Customers = React.lazy(() => import("../views/customers/Customers").then());
const CustomerCreateUpdate = React.lazy(() => import("../views/customers/CustomerCreateUpdate").then());

// Billing
const Invoices = React.lazy(() => import("../views/billing/invoices/Invoices").then());
const InvoiceCreateUpdate = React.lazy(() => import("../views/billing/invoices/InvoiceCreateUpdate").then());

const BillingCustomers = React.lazy(() => import("../views/billing/customers/Customers").then());
const BillingCustomerCreateUpdate = React.lazy(() => import("../views/billing/customers/CustomerCreateUpdate").then());


// Users
const Users = React.lazy(() => import("../views/users/Users").then());
const UserCreateUpdate = React.lazy(() => import("../views/users/UserCreateUpdate").then());

const Account = React.lazy(() => import("../views/users/Account").then());
const Support = React.lazy(() => import("../views/support/Support").then());

export const routes = {
    publicRoutes: [
        { path: "/track", name: "Track", component: TrackShipment, },
        { path: "/drivers/application", name: "Driver Main", component: DriverApplication, },
    ],

    authRoutes: [
        { path: "/admin/login", name: "Login", component: SignIn, },
    ],

    adminRoutes: [
        {
            path: '/dashboard', exact: true, name: 'Dashboard', id: 'Dashboard', component: Dashboard,
            icon: <RiDashboard3Line/>,
            containsHome: true,
            roles: ['Super','Admin','User','Station','Tracking','Billing']
        },
        {
            path: '/loads', exact: true, name: 'Loads', id: 'Loads', component: Loads, icon: <BiPackage/>,
            roles: ['Super','Admin','User','Station'],
            children: [
                { path: "/loads", exact: true, name: 'View Loads', breadcrumb: 'Loads', component: Loads, roles: ['Super','Admin','User','Station'] },
                { path: '/loads/create', name: 'Create Load', component: LoadCreateUpdate, roles: ['Super','Admin','User','Station'] },
                { path: '/loads/update/:id', name: 'Update Load', component: LoadCreateUpdate, roles: ['Super','Admin','User','Station'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/shipments', exact: true, name: 'Shipments', id: 'Shipments', component: Shipments, icon: <BiDirections/>,
            roles: ['Super','Admin','User','Station','Tracking','Billing'],
            children: [
                { path: "/shipments", exact: true, name: "View Shipments", breadcrumb: 'Shipments', component: Shipments, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/shipments/complete', name: 'Completed Shipments', component: Shipments, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/shipments/view/:id', name: 'Shipment Details', component: ShipmentView, roles: ['Super','Admin','User','Station','Tracking','Billing'], excludeFromSidebar: true },
                { path: '/shipments/create', name: 'Create Shipment', component: ShipmentCreateUpdate, roles: ['Super','Admin','User','Station','Tracking','Billing'], showSep: true },
                { path: '/shipments/update/:id', name: 'Update Shipment', component: ShipmentCreateUpdate, roles: ['Super','Admin','User','Station','Tracking','Billing'], excludeFromSidebar: true },
                { path: "/drivers", exact: true, name: "View Drivers", breadcrumb: 'Drivers', component: Drivers, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/drivers/create', name: 'Create Driver', component: DriverCreateUpdate, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/drivers/update/:id', name: 'Update Drivers', component: DriverCreateUpdate, roles: ['Super','Admin','User','Station','Tracking','Billing'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/bols', exact: true, name: 'Bill of Ladings', id: 'Bill of Ladings', component: Bols, icon: <IoDocumentTextOutline/>,
            roles: ['Super','Admin','User','Station','Tracking','Billing'],
            children: [
                { path: '/bols', exact: true, name: 'View Bill of Ladings', component: Bols, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/bols/complete', exact: true, name: 'Completed Bill of Ladings', component: Bols, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/bols/create', name: 'Create Bill of Lading', component: BolCreateUpdate, roles: ['Super','Admin','User','Station','Tracking','Billing'] },
                { path: '/bols/update/:id', name: 'Update Bill of Lading', component: BolCreateUpdate, roles: ['Super','Admin','User','Station','Tracking','Billing'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/carriers', exact: true, name: 'Carriers', id: 'Carriers', component: Carriers, icon: <RiTruckLine/>,
            roles: ['Super','Admin','Station','Billing'],
            children: [
                { path: "/carriers", exact: true, name: "View Carriers", breadcrumb: 'Carriers', component: Carriers, roles: ['Super','Admin','Station','Billing'] },
                { path: '/carriers/inactive', name: 'Inactive Carriers', component: Carriers, roles: ['Super','Admin','Station','Billing'] },
                { path: '/carriers/create', name: 'Create Carrier', component: CarrierCreateUpdate, roles: ['Super','Admin','Station','Billing'] },
                { path: '/carriers/update/:id', name: 'Update Carrier', component: CarrierCreateUpdate, roles: ['Super','Admin','Station','Billing'], excludeFromSidebar: true },
            ]
        },
        {
            path: '/contracts', exact: true, name: 'Contracts', id: 'Contracts', component: Contracts, icon: <IoDocumentTextOutline/>,
            roles: ['Super','Admin','User','Station','Billing'],
            children: [
                { path: '/contracts', exact: true, name: 'Contracts', component: Contracts, roles: ['Super','Admin','User','Station','Billing']},
                { path: '/contracts/complete', exact: true, name: 'Completed Contracts', component: Contracts, roles: ['Super','Admin','User','Station','Billing'] },
                { path: '/contracts/create', name: 'Create Contract', component: ContractCreateUpdate, roles: ['Super','Admin','User','Station','Billing'] },
                { path: '/contracts/update/:id', name: 'Update Contract', component: ContractCreateUpdate, roles: ['Super','Admin','User','Station','Billing'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/units', exact: true, name: 'Units', id: 'Units', component: Units, icon: <RiTruckLine/>,
            roles: ['Super','Admin','Station','Billing'],
            children: [
                { path: "/units", exact: true, name: "View Units", breadcrumb: 'Units', component: Units, roles: ['Super','Admin','Station','Billing'] },
                { path: '/units/inactive', name: 'Inactive Units', component: Units, roles: ['Super','Admin','Billing','Station'] },
                { path: '/units/create', name: 'Create Unit', component: UnitCreateUpdate, roles: ['Super','Admin','Billing','Station'] },
                { path: '/units/update/:id', name: 'Update Unit', component: UnitCreateUpdate, roles: ['Super','Admin','Station','Billing'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/customers', exact: true, name: 'Customers', id: 'Customers', component: Customers, icon: <RiContactsBook2Line/>,
            roles: ['Super','Admin','Billing','Station'],
            children: [
                { path: "/customers", exact: true, name: "View Customers", breadcrumb: 'Customers', component: Customers, roles: ['Super','Admin','Billing','Station'] },
                { path: '/customers/create', name: 'Create Customer', component: CustomerCreateUpdate, roles: ['Super','Admin','Billing','Station'] },
                { path: '/customers/update/:id', name: 'Update Customer', component: CustomerCreateUpdate, roles: ['Super','Admin','Billing','Station'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/billing/invoices', exact: true, name: 'Invoices', id: 'Billing', component: Invoices, icon: <RiMoneyDollarBoxLine/>,
            roles: ['Super','Admin','Billing'],
            children: [
                { path: '/billing', exact: true, name: 'Billing', roles: ['Super','Admin','Billing'], excludeFromSidebar: true },
                { path: '/billing/customers', exact: true, name: 'View Customers', breadcrumb: 'Customers', component: BillingCustomers, roles: ['Super','Admin','Billing']},
                { path: '/billing/customers/create', name: 'Create Customer', component: BillingCustomerCreateUpdate, roles: ['Super','Admin','Billing'], showSep: true },
                { path: '/billing/customers/update/:id', name: 'Update Customer', component: BillingCustomerCreateUpdate, roles: ['Super','Admin','Billing'], excludeFromSidebar: true },
                { path: '/billing/invoices', exact: true, name: 'View Invoices', breadcrumb: 'Invoices', component: Invoices, roles: ['Super','Admin','Billing']},
                { path: '/billing/invoices/complete', exact: true, name: 'Completed Invoices', component: Invoices, roles: ['Super','Admin','Billing'] },
                { path: '/billing/invoices/create', name: 'Create Invoice', component: InvoiceCreateUpdate, roles: ['Super','Admin','Billing'] },
                { path: '/billing/invoices/update/:id', name: 'Update Invoice', component: InvoiceCreateUpdate, roles: ['Super','Admin','Billing'], excludeFromSidebar: true }
            ]
        },
        {
            path: '/users', exact: true, name: 'Users', id: 'Users', component: Users, icon: <FiUsers/>,
            roles: ['Super','Admin'],
            children: [
                { path: "/users", exact: true, name: "View Users", breadcrumb: 'Users', component: Users, roles: ['Super','Admin'] },
                { path: '/users/create', name: 'Create User', component: UserCreateUpdate, roles: ['Super','Admin'] },
                { path: '/users/update/:id', name: 'Update User', component: UserCreateUpdate, roles: ['Super','Admin'], excludeFromSidebar: true }
            ]
        },
        { path: '/account',
            exact: true,
            name: 'Account',
            id: 'Account',
            component: Account,
            excludeFromSidebar: true,
            roles: ['Super','Admin','User','Station','Tracking','Billing']
        },
        { path: '/support',
            exact: true,
            name: 'Support',
            id: 'Support',
            component: Support,
            excludeFromSidebar: true,
            roles: ['Super','Admin','User','Station','Tracking','Billing']
        }
    ],
}

/**
 * Map admin prefix to admin routes
 */
routes.adminRoutes.map((route) => {
    if (route.children) {
        route.children.map((child) => {
            child.path = adminPrefix + child.path;
            return child;
        })
    }
    route.path = adminPrefix + route.path;
    return route;
})

export default routes;

