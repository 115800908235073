import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
    Avatar as MuiAvatar,
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Popover as MuiPopover,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import { Bell } from "react-feather";
import { Close, EventNote, EventAvailable, EventBusy, Notifications } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { adminPrefix } from "../../routes";
import NotificationService from "../../services/NotificationService";
import { setNotifications } from "../../redux/actions/ThemeActions";
import _ from 'lodash';

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const NotificationsDropdown = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const notifications = useSelector(state => state.theme.notifications);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUrl = (url) => {
        if (url) {
            history.push(adminPrefix + url);
            handleClose();
        }
    }

    const clearNotification = async (id) => {
        await NotificationService.remove(id);
        dispatch(setNotifications());
    };

    const clearNotifications = async () => {
        let ids = [];
        _.forEach(notifications, o => {
            ids.push(o.id);
        })

        await NotificationService.removeAll(ids);
        dispatch(setNotifications());
    };

    return (
        <React.Fragment>
            <Tooltip title="Notifications">
                <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
                    <Indicator badgeContent={notifications.length}>
                        <Bell />
                    </Indicator>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <NotificationHeader p={2}>
                    <Typography variant="subtitle1" color="textPrimary">
                        {notifications.length} New Notifications
                    </Typography>
                </NotificationHeader>
                <React.Fragment>
                    <List disablePadding>
                        {notifications.map(o => {
                            let icon;
                            switch (o.type) {
                                case 'tor':
                                    switch (o.status) {
                                        case 'approved':
                                            icon = <EventAvailable/>;
                                            break;
                                        case 'denied':
                                            icon = <EventBusy/>;
                                            break;
                                        case 'pending':
                                        default:
                                            icon = <EventNote/>;
                                            break;
                                    }
                                    break;
                                default:
                                    icon = <Notifications/>;
                            }
                            return (
                                <ListItem divider button onClick={() => handleUrl(o.url)} key={o.id}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <SvgIcon fontSize="small">
                                                {icon}
                                            </SvgIcon>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={o.title}
                                        primaryTypographyProps={{
                                            variant: "subtitle2",
                                            color: "textPrimary"
                                        }}
                                        secondary={o.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => clearNotification(o.id)} edge="end" aria-label="delete" size="large">
                                            <Close fontSize="small" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}

                    </List>
                    {notifications.length > 0 &&
                    <Box p={1} display="flex" justifyContent="center">
                        <Button size="small" onClick={clearNotifications}>
                            Clear all notifications
                        </Button>
                    </Box>}
                </React.Fragment>
            </Popover>
        </React.Fragment>
    );
}

export default NotificationsDropdown;
