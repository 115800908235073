import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";


import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';

import createTheme from "./theme";
import "./scss/style.scss";
import Routes from "./routes/Routes";
import AxiosSetup from "./utils/axios";
import loadScript from "./utils/loadScripts";
import * as types from "./redux/constants";
import { setNotifications } from "./redux/actions/ThemeActions";

const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-insertion-point")
});

function App() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    useEffect(() => {
        // Set theme
        if (state.auth.user?.preferences?.theme) {
            dispatch({ type: types.THEME_SET, payload: state.auth.user.preferences.theme });
        }

        // Set notifications
        dispatch(setNotifications());
    }, [state.auth.user?.preferences?.theme, dispatch]);

    /* Load Google Maps */
    const mapsLoaded = useRef(false);
    if (typeof window !== "undefined" && !mapsLoaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }
        mapsLoaded.current = true;
    }

    return (
        <React.Fragment>
            <HelmetProvider>
                <SnackbarProvider maxSnack={1}
                                  anchorOrigin={{ vertical: "top", horizontal: "right", preventDuplicate: true }}>
                    <AxiosSetup />
                    <Helmet
                        titleTemplate="%s | 1 AA XPRESS"
                        defaultTitle="1 AA XPRESS"
                    />
                    <StyledEngineProvider injectFirst>
                        <StylesProvider jss={jss}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MuiThemeProvider theme={createTheme(state.theme.currentTheme)}>
                                    <ThemeProvider theme={createTheme(state.theme.currentTheme)}>
                                        <Routes />
                                    </ThemeProvider>
                                </MuiThemeProvider>
                            </LocalizationProvider>
                        </StylesProvider>
                    </StyledEngineProvider>
                </SnackbarProvider>
            </HelmetProvider>
        </React.Fragment>
    );
}

export default App;
