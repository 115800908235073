import axios from "axios";
import {refreshToken, signOut} from "../redux/actions/AuthActions";
import store from '../redux/store';
import {useSnackbar} from 'notistack';

const AxiosSetup = () => {

    const { enqueueSnackbar } = useSnackbar();

    axios.defaults.withCredentials = true;
    let baseUrl;
    switch (process.env.REACT_APP_ENV) {
        case 'production':
            baseUrl = process.env.REACT_APP_API_URL_PROD;
            break;
        case 'test':
            baseUrl = process.env.REACT_APP_API_URL_TEST;
            break;
        case 'dev':
        default:
            baseUrl = process.env.REACT_APP_API_URL_DEV;
    }
    axios.defaults.baseURL = baseUrl;

    if (axios.interceptors.request.handlers.length > 0) {
        axios.interceptors.request.handlers = [];
    }
    axios.interceptors.request.use(req => {

        // Log requests in development
        if (process.env.REACT_APP_ENV === 'dev') console.log('Axios Request', req);

        const token = store.getState().auth.user.token;
        if (token) {
            req.headers.Authorization = 'Bearer ' + token;
        } else {
            req.headers.Authorization = null;
        }
        return req;
    });

    axios.interceptors.response.use(res => {
            return res;
        }, async (error) => {
            if (!error.response) {
                console.log('Network Error');
                enqueueSnackbar('Network Error: Please try again.', { variant: "error" });
                return Promise.reject(error);
            }
            if (401 === error.response.status && error.config && !error.config._isRetry) {
                error.response.config._isRetry = true;

                if ('/refresh' !== error.response.config.url) {
                    await store.dispatch(
                        refreshToken()
                    );
                    return axios.request(error.config);
                } else {
                    await store.dispatch(
                        signOut()
                    );
                }
            }
            return Promise.reject(error);
        }
    );

    return null;
}

export default AxiosSetup;